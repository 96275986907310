import { defineComponent, defineAsyncComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: 'LiveFence',
  components: {
    FenceTable: defineAsyncComponent(() => import('../safetyFence/FenceTable.vue'))
  },
  emits: ['add', 'rowClick'],
  setup() {
    const refData = reactive({
      refTable: null
    });
    function openFenceDailog(info) {
      refData.refTable.openFenceDailog(info);
    }
    return {
      ...toRefs(refData),
      openFenceDailog
    };
  }
});